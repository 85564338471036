[data-body-bg="color_1"]{
	background-color: #a6a6a6;
}
[data-body-bg="color_2"] {
	background-color: #FF0000; /* red */
  }
  

[data-body-bg="color_3"]{
	background-color: #ee2737;
}
[data-body-bg="color_4"]{
	background-color: #a75d5d;
}
[data-body-bg="color_5"]{
	background-color: #ea5c2b;
}
[data-body-bg="color_6"]{
	background-color: #a9907e;
}
[data-body-bg="color_7"]{
	background-color: #87805e;
}
